'use strict';

export default class Gallery {
  constructor() {
    this.name = 'gallery';
    console.log('%s module', this.name.toLowerCase());


  }
}

